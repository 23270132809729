import { useContext } from "react";
import styles from "./styles.module.css";
import { BasicFilterContext } from "context-api/BasicFilter";

const BasicFilter = () => {
  const { basicFilter, setBasicFilter } = useContext(BasicFilterContext);
  const fields = [
    { key: "light", value: "Light" },
    { key: "basic", value: "Basic" },
    { key: "flex", value: "Flex" },
  ];
  return (
    <div className={styles.container}>
      {fields.map((field, index) => (
        <span
          key={index}
          className={styles.button}
          data-active={basicFilter === field.key}
          onClick={() => {
            setBasicFilter(field.key);
          }}
        >
          {field.value.toUpperCase()}
        </span>
      ))}
    </div>
  );
};

export default BasicFilter;

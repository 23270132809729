import React, { useContext } from "react";
import Card from "components/common/Card/Card";
import { FilterContext } from "context-api/FilterContext";
import { FareStructureClient } from "lib/api/lfa/lowestFareCalendar/FareStructure";
import styles from "./styles.module.css";
import images from "constans/images";
import CustomAtarevTable from "components/common/Table/CustomTable";
import { ICustomAtarevTable } from "types/types";
import moment from "moment";
import { Tooltip } from "components/common/Tooltip";
import Ancillaries from "../Ancillaries";

const FareStructureTable = ({ setVisible, selectedDate }) => {
  const { filterList } = useContext(FilterContext);
  const [data, setData] = React.useState<ICustomAtarevTable>({
    data: [],
    columns: [],
  });
  const [switchButton, setSwitchButton] = React.useState(false);

  React.useEffect(() => {
    if (selectedDate) {
      const getMonth = selectedDate.getMonth(); // getMonth parametresi ile seçilmiş tarihte hangi ayda olduğumuzu buluyorum
      const getFullYear = selectedDate.getFullYear(); // getFullYear parametresi ile seçilmiş tarihte hangi yılda olduğumuzu buluyorum
      const dayOfMonth = new Date(getFullYear, getMonth + 1, 0).getDate();
      const firstDayCurrentMonth = new Date(getFullYear, getMonth, 1); // seçilmiş tarihin ilk gününü buluyorum. 04.11.2022 tarihinin ilk günü 01.11.2022 gibi
      const endDayCurrentMonth = new Date(getFullYear, getMonth, dayOfMonth); // seçilmiş tarihin son gününü buluyorum. 04.11.2022 tarihinin son günü 30.11.2022 gibi
      const data = {
        origin: filterList.origCityAirport.join(),
        destination: filterList.destCityAirport.join(),
        cabin: filterList.cabin.join(),
        date_range_start: moment(firstDayCurrentMonth).format("YYYY-MM-DD"),
        date_range_end: moment(endDayCurrentMonth).format("YYYY-MM-DD"),
      };

      FareStructureClient.fetchFareStructure({ data })
        .then((res: any) => {
          setData({
            data: res?.table ?? [],
            columns: getColumns(res?.labels),
          });
        })
        .catch((err) => console.log(err));
    }
  }, [filterList, selectedDate]);

  const getColumns = (labels) => {
    const array: any = [];
    for (const [field, name] of Object.entries(labels)) {
      const obj = {
        name,
        field,
        cell: (row) => {
          return (
            <Tooltip title={row[field] ?? ""}>
              <span className={"rdb-custom-cell"}>{row[field]}</span>
            </Tooltip>
          );
        },
        width: 50,
      };
      array.push(obj);
    }
    return array;
  };
  return (
    <div
      style={{
        minWidth: 500,
        backgroundColor: "rgb(17, 12, 32)",
        borderRadius: 12,
      }}
    >
      <Card variant="secondary">
        <div className={styles.close_button_wrapper}>
          <span
            className={styles.switch_wrapper}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <span
              className={styles.switch_button}
              data-active={!switchButton}
              onClick={() => {
                setSwitchButton(!switchButton);
              }}
            >
              Table
            </span>
            <span
              className={styles.switch_button}
              data-active={switchButton}
              onClick={() => {
                setSwitchButton(!switchButton);
              }}
            >
              Ancillaries
            </span>
          </span>
          <span
            className={styles.close_button}
            onClick={(e) => {
              e.stopPropagation();
              setVisible(false);
            }}
          >
            <img src={images.cancel_red} alt="" />
          </span>
        </div>
        {switchButton ? (
          <Ancillaries />
        ) : (
          <CustomAtarevTable
            data={data.data}
            columns={data.columns}
            height={600}
            isShowing={false}
            rowHeight={32}
          />
        )}
      </Card>
    </div>
  );
};

export default FareStructureTable;
